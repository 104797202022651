import React, { useReducer, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StyledImageUploader from './ImageUploader.Style';
import StyledStagedImages from './StagedImages.Style';
import UploadCard from './UploadCard';
import StagedImage from './StagedImage';
import StagedLandImage from './StagedLandImage';
import StagedFeaturedImage from './StagedFeaturedImage';
import ToastUndo from '../ToastUndo';
import { ERROR_TOAST_OPTIONS } from '../../constants';
import LoadingDots from '../LoadingDots';
import ImageEditor from '../ImageEditor/ImageEditor';
import { convertPdfToImages } from './PdfToImage';

const reducer = (state, action) => {
  switch (action.type) {
    case 'addImage':
      return {
        ...state,
        stagedImages: [...state.stagedImages, action.payload],
      };
    case 'setImages':
      return {
        ...state,
        stagedImages: [...action.payload],
      };

    case 'removeImage':
      return {
        ...state,
        stagedImages: (arr => {
          arr.splice(action.payload, 1);
          return arr;
        })(state.stagedImages),
      };
    case 'addFeaturedImage':
      return {
        ...state,
        stagedFeaturedImage: [...state.stagedFeaturedImage, action.payload],
      };
    case 'setFeaturedImage':
      return {
        ...state,
        stagedFeaturedImage: [...action.payload],
      };

    case 'removeFeaturedImage':
      return {
        ...state,
        stagedFeaturedImage: (arr => {
          arr.splice(action.payload, 1);
          return arr;
        })(state.stagedFeaturedImage),
      };
    case 'setIsUploading':
      return { ...state, isUploading: action.payload };
    default:
      return state;
  }
};

const initialState = {
  stagedImages: [],
  stagedFeaturedImage: [],
  isUploading: false,
};

export const ImageUpload = ({
  uploadFunction,
  rooms,
  minHeight,
  minWidth,
  stageImages,
  stagedFeaturedImage,
  allowMultiple,
  headerText,
  aspectRatioText,
  showLibraryImagesModal,
  imageType,
  isFeaturedImage,
  isWelcomeHomeImage,
  canUploadFloorPlan,
}) => {
  // const { addNotification } = useContext(NotificationContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const [imageToEdit, setImageToEdit] = useState(null);

  const [welcomeHomeImageError, setWelcomeHomeImageError] = useState('');

  const editImage = image => {
    setImageToEdit(image);
  };

  const cancelEditingImage = useCallback(() => {
    // close the dialog
    setImageToEdit(null);
  }, []);

  const doneEditingImage = useCallback(
    editedImage => {
      // update the staged image
      imageToEdit.uri = editedImage.dataUri;
      imageToEdit.file = editedImage.file;
      let imageIndex = state.stagedImages.indexOf(imageToEdit);

      updateImage(imageIndex, imageToEdit);

      // close the dialog
      setImageToEdit(null);
    },
    [imageToEdit]
  );

  const doneEditingFeaturedImage = useCallback(
    editedImage => {
      // update the staged image
      imageToEdit.uri = editedImage.dataUri;
      imageToEdit.file = editedImage.file;
      let imageIndex = state.stagedFeaturedImage.indexOf(imageToEdit);

      updateFeaturedImage(imageIndex, imageToEdit);

      // close the dialog
      setImageToEdit(null);
    },
    [imageToEdit]
  );

  /**
   * validations an image's dimensions after it has been loaded
   *
   * @param {string} imageUri the uri of the image to be validated
   */
  const validateImageDimensions = imageUri => {
    const img = new Image();
    return new Promise((resolve, reject) => {
      img.onload = () => {
        resolve(img.height >= minHeight && img.width >= minWidth);
      };

      img.onerror = () => {
        reject(new Error('Image failed to load'));
      };
      img.src = imageUri;
    });
  };

  const addImage = async image => {
    if (await validateImageDimensions(image.uri)) {
      dispatch({ type: 'addImage', payload: image });
    } else {
      toast.error(
        <div>
          {`${image.file.name} could not be added.`}
          <br />
          {`Images must be ${minWidth} x ${minHeight} minimum.`}
        </div>,
        ERROR_TOAST_OPTIONS
      );
    }
  };

  const addFeaturedImage = async image => {
    if (await validateImageDimensions(image.uri)) {
      dispatch({ type: 'addFeaturedImage', payload: image });
    } else {
      toast.error(
        <div>
          {`${image.file.name} could not be added.`}
          <br />
          {`Images must be ${minWidth} x ${minHeight} minimum.`}
        </div>,
        ERROR_TOAST_OPTIONS
      );
    }
  };

  const handleFile = file => {
    if (
      !('type' in file) ||
      (file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'application/pdf')
    ) {
      toast.error(
        <div>
          {`${file.name} could not be added.`}
          <br />
          Images must be JPG, JPEG or PNG.
        </div>,
        ERROR_TOAST_OPTIONS
      );
      return false;
    }

    if (file.type === 'application/pdf') {
      handlePdfConversion(file);
    } else {
      handleImageDataURL(file, URL.createObjectURL(file));
    }

    return true;
  };

  const handlePdfConversion = async file => {
    const imageDataUrls = await convertPdfToImages(file);
    // convert image to file/blob for upload
    const blob = dataURItoBlob(imageDataUrls[0]);
    const fileOfBlob = new File([blob], file.name.replace('.pdf', '.png'));

    handleImageDataURL(fileOfBlob, imageDataUrls[0]);
  };

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);

    var mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var bb = new Blob([ab], { type: mimeString });
    bb.name = 'test.png';
    return bb;
  }

  const handleImageDataURL = async (file, imageDataUrl) => {
    const image = {
      file,
      fileName: file.name,
      uri: imageDataUrl,
      caption: '',
    };

    if (imageType === 'home') {
      image.imageTypeAcronym = 'INT';
      image.roomId = '1';
    }

    if (!allowMultiple) {
      // upload and attach
      if (await validateImageDimensions(image.uri)) {
        dispatch({ type: 'setIsUploading', payload: true });
        uploadFunction(image);
        if (isWelcomeHomeImage) {
          dispatch({ type: 'setIsUploading', payload: false });
        }
      } else {
        toast.error(
          <div>
            {`${image.file.name} could not be added.`}
            <br />
            {`Images must be ${minWidth} x ${minHeight} minimum.`}
          </div>,
          ERROR_TOAST_OPTIONS
        );
      }
    } else {
      if (isFeaturedImage) {
        addFeaturedImage(image).then();
      } else {
        addImage(image).then();
      }
    }
  };

  const handleDrop = event => {
    Array.from(event.dataTransfer.files).forEach(file => {
      handleFile(file);
    });
  };

  const handleChange = event => {
    Array.from(event.target.files).forEach(file => {
      handleFile(file);
    });
  };

  const updateImage = (index, image) => {
    const newImages = [...state.stagedImages];
    newImages[index] = image;
    dispatch({ type: 'setImages', payload: newImages });
  };

  const updateFeaturedImage = (index, image) => {
    const newImages = [...state.stagedFeaturedImage];
    newImages[index] = image;
    dispatch({ type: 'setImages', payload: newImages });
  };

  const removeImage = index => {
    const deletedImage = state.stagedImages[index];

    const undoFunction = () => {
      addImage(deletedImage).then();
    };

    dispatch({ type: 'removeImage', payload: index });

    toast.info(
      <ToastUndo
        undo={undoFunction}
        message={`${deletedImage.file.name} removed`}
      />,
      {
        className: 'undo-toast',
        progressClassName: 'undo-progress-bar',
        closeButton: false,
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
  };

  const removeFeaturedImage = index => {
    const deletedImage = state.stagedFeaturedImage[index];

    const undoFunction = () => {
      addFeaturedImage(deletedImage).then();
    };

    dispatch({ type: 'removeFeaturedImage', payload: index });

    toast.info(
      <ToastUndo
        undo={undoFunction}
        message={`${deletedImage.file.name} removed`}
      />,
      {
        className: 'undo-toast',
        progressClassName: 'undo-progress-bar',
        closeButton: false,
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
  };

  const uploadImages = () => {
    if (state.isUploading) return;

    let imagesUploading = 0;
    dispatch({ type: 'setIsUploading', payload: true });

    state.stagedImages.map(async (image, index) => {
      imagesUploading += 1;

      const shouldRefreshData = imagesUploading == 1;
      await uploadFunction(image, shouldRefreshData);
      imagesUploading -= 1;

      dispatch({ type: 'removeImage', payload: index });

      if (imagesUploading === 0) {
        dispatch({ type: 'setIsUploading', payload: false });
        dispatch({ type: 'setImages', payload: [] });
      }
    });
  };

  const uploadFeaturedImage = () => {
    if (state.isUploading) return;

    let imagesUploading = 0;
    dispatch({ type: 'setIsUploading', payload: true });

    state.stagedFeaturedImage.map(async (image, index) => {
      imagesUploading += 1;

      await uploadFunction(image);
      imagesUploading -= 1;

      dispatch({ type: 'removeFeaturedImage', payload: index });

      if (imagesUploading === 0) {
        dispatch({ type: 'setIsUploading', payload: false });
        dispatch({ type: 'setFeaturedImage', payload: [] });
      }
    });
  };

  const allowFloorPlanUpload = canUploadFloorPlan && !(
    // has an image upload in queue been marked as flp?
    state.stagedImages.some(image => image.imageTypeAcronym === 'FLP')
  );

  return (
    <StyledImageUploader className="image-uploader">
      {imageToEdit && (
        <ImageEditor
          imageData={imageToEdit.uri}
          imageFileNameOverride={imageToEdit.fileName}
          onDoneEditing={
            isFeaturedImage ? doneEditingFeaturedImage : doneEditingImage
          }
          onCancelEditing={cancelEditingImage}
        />
      )}

      <UploadCard
        handleChange={handleChange}
        handleDrop={handleDrop}
        allowMultiple={allowMultiple}
        headerText={headerText}
        footerText={`Images must be ${minWidth} x ${minHeight} minimum`}
        showLibraryImagesModal={showLibraryImagesModal}
        isUploading={state.isUploading}
      />
      {stageImages && !isFeaturedImage && (
        <StyledStagedImages>
          {state.stagedImages.length > 0 && (
            <>
              {state.stagedImages.map((image, index) => {
                return imageType === 'home' ? (
                  <StagedImage
                    key={image.file.name}
                    index={index}
                    image={image}
                    removeImage={removeImage}
                    updateImage={updateImage}
                    handleEditImage={editImage}
                    rooms={rooms}
                    allowFloorPlanUpload={allowFloorPlanUpload}
                  />
                ) : (
                  <StagedLandImage
                    key={image.file.name}
                    index={index}
                    image={image}
                    removeImage={removeImage}
                    updateImage={updateImage}
                    handleEditImage={editImage}
                  />
                );
              })}
              {state.isUploading && (
                <div className="finish-button">
                  <LoadingDots />
                </div>
              )}
              {!state.isUploading && (
                <button
                  type="button"
                  className="finish-button"
                  onClick={uploadImages}
                  disabled={state.isUploading}
                >
                  Finish Upload
                </button>
              )}
            </>
          )}
        </StyledStagedImages>
      )}
      {stagedFeaturedImage && isFeaturedImage && (
        <StyledStagedImages>
          {state.stagedFeaturedImage.length > 0 && (
            <>
              {state.stagedFeaturedImage.map((image, index) => {
                return (
                  <StagedFeaturedImage
                    key={image.file.name}
                    index={index}
                    image={image}
                    removeImage={removeFeaturedImage}
                    updateImage={updateFeaturedImage}
                    handleEditImage={editImage}
                    rooms={rooms}
                  />
                );
              })}
              {state.isUploading && (
                <div className="finish-button">
                  <LoadingDots />
                </div>
              )}
              {!state.isUploading && (
                <button
                  type="button"
                  className="finish-button"
                  onClick={uploadFeaturedImage}
                  disabled={state.isUploading}
                >
                  Finish Upload
                </button>
              )}
            </>
          )}
        </StyledStagedImages>
      )}
    </StyledImageUploader>
  );
};

ImageUpload.propTypes = {
  uploadFunction: PropTypes.func.isRequired,
  rooms: PropTypes.shape(),
  minHeight: PropTypes.number,
  minWidth: PropTypes.number,
  stageImages: PropTypes.bool,
  stagedFeaturedImage: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  headerText: PropTypes.string.isRequired,
  showLibraryImagesModal: PropTypes.func,
  imageType: PropTypes.string,
};

ImageUpload.defaultProps = {
  rooms: {},
  minHeight: 600,
  minWidth: 900,
  stageImages: true,
  stagedFeaturedImage: true,
  allowMultiple: true,
  showLibraryImagesModal: null,
  imageType: 'home',
};

export default ImageUpload;
