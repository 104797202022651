import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StyledSaleHomeListingCard from './SaleHomeListingCard.Style';
import PosedSaleHomeListingCard from './SaleHomeListingCard.Pose';
import Toggle from './Toggle';
import InfoIcon from '../icons/InfoIcon';
import { canEditSaleHome, canEditVisibility, canEditSticker } from '../utils';

function SaleHomeListingCard({
  to,
  updateAttribute,
  home,
  labels,
  getErrors,
  ...props
}) {
  return useMemo(() => {
    const { Name, isUsed, SerialNumber, ModelNumber, IsSaleHome } = home;
    const errors = getErrors(home);
    return (
      <StyledSaleHomeListingCard {...props}>
        <div className="card">
          <PosedSaleHomeListingCard className="card-body">
            <Link to={to}>
              <div className="left-area">
                <div className="home-info">
                  <h2>{Name}</h2>
                  {isUsed && <div className="tag">used</div>}
                  <div className="home-info-container">
                    <div className="labels-container">
                      {labels.length > 0 &&
                        labels.map(label => (
                          <div key={label.text}>
                            <p>
                              <span>{label.text}</span>
                            </p>
                            <p>{label.value}</p>
                          </div>
                        ))}
                    </div>
                    {errors &&
                      errors.map(error => (
                        <div className="error" key={error.message}>
                          <InfoIcon />
                          <span>{error.message}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Link>
            <div className="right-area">
              <Toggle
                size="large"
                onToggle={event => {
                  updateAttribute('IsSaleHome', event.currentTarget.checked);
                }}
                inputProps={{
                  id: `${SerialNumber || ModelNumber}-sale`,
                  disabled: !canEditSaleHome(home),
                  checked: IsSaleHome && canEditSaleHome(home),
                }}
              />
            </div>
          </PosedSaleHomeListingCard>
        </div>
      </StyledSaleHomeListingCard>
    );
  }, [home, updateAttribute, getErrors, labels, props, to]);
}

SaleHomeListingCard.propTypes = {
  to: PropTypes.shape().isRequired,
  updateAttribute: PropTypes.func.isRequired,
  home: PropTypes.shape({
    Name: PropTypes.string,
    IsSaleHome: PropTypes.bool,
    ModelNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    SerialNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    IsUsed: PropTypes.bool,
  }).isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
};

export default SaleHomeListingCard;
