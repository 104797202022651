import React, { useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import StyledInventoryImageCard from './InventoryImageCard.Style';
import { IMAGE_TYPES } from '../../constants';
import { formatDate } from '../../utils';
import HomeSvg from '../HomeSvg';
import EditImageModal from './EditImageModal';
import Modal from '../Modal';
import SelectCheckmark from '../../icons/SelectCheckmark';
import Magnify from '../../icons/Magnify';

const cardSource = {
  beginDrag: props => ({
    id: props.id,
    index: props.index,
  }),
};

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

const ImageCard = React.forwardRef(
  (
    {
      image,
      isDragging,
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      style,
      saveFunction,
      deleteFunction,
      rooms,
      isUpdating,
      isLoading,
      inspectImageFunction,
      onSelect,
      isSelected,
      isOpaque,
      homeOnLand,
      allowFloorPlan,
    },
    ref
  ) => {
    const elementRef = useRef(null);
    const previewRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    connectDragSource(elementRef);
    connectDropTarget(previewRef);
    connectDragPreview(previewRef);
    const opacity = isDragging ? 0.2 : 1;
    const transition = isDragging ? 'none' : 'inherit';
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }));
    const toggleModal = () => {
      setShowModal(!showModal);
    };
    const toggleDeleteModal = () => {
      setShowDeleteModal(!showDeleteModal);
    };

    return (
      <div
        ref={previewRef}
        style={{ ...style, opacity, transition }}
        className={
          homeOnLand && isSelected
            ? 'card selected'
            : homeOnLand && isOpaque
            ? 'card opaque'
            : 'card'
        }
      >
        {image.ImageTypeAcronym !== 'FLP' && (
          <div ref={elementRef} className="draggable-bars">
            <div className="dots-container">
              <span className="dot" />
              <span className="dot" />
            </div>
            <div className="dots-container">
              <span className="dot" />
              <span className="dot" />
            </div>
            <div className="dots-container">
              <span className="dot" />
              <span className="dot" />
            </div>
          </div>
        )}
        {homeOnLand && isSelected && (
          <div className="check">
            <SelectCheckmark />
          </div>
        )}
        {homeOnLand && !isSelected && isOpaque && <div className="overlay" />}
        <StyledInventoryImageCard>
          {image.Reference && (
            <img src={`${image.Reference}?width=300`} alt="" />
          )}
          {!image.Reference && <HomeSvg />}
          <div className="label">
            <span className="bold">
              {(image.ModelsImagesId ? 'HBF ' : '') +
                IMAGE_TYPES[image.ImageTypeAcronym]}
            </span>
            {image.ImageTypeAcronym === 'INT' && (
              <span className="room">{image.RoomDescription}</span>
            )}
            <Magnify
              className="search-icon"
              color="#25292D"
              onClick={() => {
                inspectImageFunction(image);
              }}
            />
            <span className="spacer" />
            {image.ImageTypeAcronym === 'FLP' && homeOnLand && !isSelected && (
              <button type="button" className="edit" onClick={onSelect}>
                Select
              </button>
            )}
            {!image.IsModelImage && (
              <button type="button" className="edit" onClick={toggleModal}>
                Edit
              </button>
            )}
          </div>

          <EditImageModal
            closeCopy="CANCEL"
            saveCopy="SAVE CHANGES"
            show={showModal}
            closeCallback={toggleModal}
            saveFunction={saveFunction}
            deleteFunction={() => {
              toggleDeleteModal();
            }}
            image={image}
            modalImg={image.Reference}
            room={image.RoomId}
            caption={image.Caption}
            lastModifiedBy={image.CreatedBy}
            lastModificationDate={formatDate(image.CreationDate)}
            group={IMAGE_TYPES[image.ImageTypeAcronym]}
            rooms={rooms}
            isUpdating={isUpdating}
            isLoading={isLoading}
            allowFloorPlan={allowFloorPlan}
          />
          <Modal
            modalHeadline="Warning: This action cannot be undone."
            modalBody="Are you sure you want to permanantly delete this image?"
            closeCopy="CANCEL"
            saveCopy="DELETE IMAGE"
            show={showDeleteModal}
            closeCallback={() => {
              setShowDeleteModal(false);
            }}
            saveCallback={() => {
              deleteFunction(image.InventoryImagesId);
              setShowDeleteModal(false);
            }}
            discardCallback={() => {
              setShowDeleteModal(false);
            }}
          />
        </StyledInventoryImageCard>
      </div>
    );
  }
);

ImageCard.propTypes = {
  image: PropTypes.shape({
    Reference: PropTypes.string.isRequired,
    RoomDescription: PropTypes.string,
    ImageTypeAcronym: PropTypes.string,
    Caption: PropTypes.string,
    CreatedBy: PropTypes.string,
    CreationDate: PropTypes.string,
  }).isRequired,
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  style: PropTypes.shape(),
  saveFunction: PropTypes.func.isRequired,
  deleteFunction: PropTypes.func.isRequired,
  rooms: PropTypes.shape().isRequired,
};

ImageCard.defaultProps = {
  style: {},
};

export default DropTarget(
  'image-card',
  {
    hover(props, monitor, component) {
      const { index, moveCard } = props;
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return null;
      }
      moveCard(dragIndex, hoverIndex);
      // (Mutating this object for performance enhancement)
      // eslint-disable-next-line no-param-reassign
      monitor.getItem().index = hoverIndex;
      return null;
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  })
)(DragSource('image-card', cardSource, collect)(ImageCard));
